import React from 'react';
import img from '../assets/images/modern.png';

function ModernFreelancer() {
  return (
    <>
     <section className='er__modernFreelancer' id='about_early'>
        <div className='container'>
            <div className='er__modernFreelancer_wrap'>
                <div className='er__modernFreelancer_det'>
                    <h4 className='zenFont'>For the modern freelancer or agency</h4>
                    <div className='er__modernFreelancer_imgWrap'>
                        <img src={img} alt='' />
                    </div>
                    <div className='er__modernFreelancer_info'>
                        <p>Early Bird is your solution to simplifying the Upwork sales cycle. Start your journey to efficient, effective, and always on job proposals and lead responses <b>so you can have more time to do what you love.</b></p>
                        <a href='/join-waitlist' className='er__joinBtn'>Join the Waitlist</a>
                    </div>
                </div>
            </div>
        </div>
     </section>
    </>
  )
}

export default ModernFreelancer

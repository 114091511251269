import React from "react";
import HubspotForm from "react-hubspot-form";

const Contact = () => {
  return (
    <div className="hubspot-form-container">
      <HubspotForm
      portalId="47036843"
      formId="43d23512-db87-40a2-8700-89c731e2ae39"
      onSubmit={() => console.log("Form submitted!")}
      onReady={(form) => console.log("Form ready!")}
      loading={<div className="d-flex align-items-center justify-content-center">Loading...</div>}
    />
    </div>
  );
};

export default Contact;

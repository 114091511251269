import React from "react";
import feature_img from "../assets/images/feature_1.png";

function Achieve() {
  const navigateTo = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <>
      <section className="er__achieve">
        <div className="container">
          <div className="er__achieve_wrap">
            <div className="er__achieve_head">
              <h3>
                Achieve consistent, timely responses to Upwork leads without the
                stress and hassle
              </h3>
            </div>
            <div className="er__achieve_bottom">
              <div className="er__achieve_left">
                <img src={feature_img} alt="featureImg" />
                {/* <p>Skip to:</p> */}
                <a
                  href=" "
                  onClick={(e) => {
                    e.preventDefault();
                    navigateTo("features_early");
                  }}
                  className="er__featureBtn"
                >
                  Features
                </a>
              </div>
              <div className="er__achieve_right">
                <p>
                  The need to constantly monitor for new leads and respond
                  instantly can be overwhelming, leading to missed opportunities
                  and inconsistent results.
                </p>
                <p>
                  If these resonate with your experience, we understand your
                  frustration:
                </p>
                <p>
                  "Why do I keep missing out on potential clients?" "Constantly
                  checking my phone is draining and disruptive." "I wish I could
                  respond instantly without being tied to my device."
                </p>
              </div>
            </div>
            <a href=" " className="er__joinBtn">
              Join the Waitlist
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Achieve;

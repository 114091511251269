import React from "react";
import feature_img from "../assets/images/feature_2.jpg";

function Simplify() {
  return (
    <>
      <section className="er__simplify">
        <div className="container">
          <div className="er__simplify_wrap">
            <div className="er__simplify_head">
              <h3>Simplify your freelance journey</h3>
            </div>
            <div className="er__simplify_bottom">
              <div className="er__simplify_left">
                <img src={feature_img} alt="featureImg" />
                <a href="/join-waitlist" className="er__joinBtn">
                  Join the Waitlist
                </a>
              </div>
              <div className="er__simplify_right">
                <p>
                  Attain freelance success with Early Bird's efficiency and
                  reliability.
                </p>
                <p>
                  <b>Sign up:</b> Assess your lead response needs. Explore our
                  comprehensive plans. Choose the right one.
                </p>
                <p>
                  <b>Personalize:</b> Customize your selected plan. Consult with
                  our team for expert guidance. Create scanners that are right
                  for your needs.
                </p>
                <p>
                  <b>Realize:</b> Watch your AI proposals get noticed and lead
                  engagement improve. Track responses through our dashboard.
                  Enjoy the benefits of consistent, accurate lead responses
                  driven by data, not gut feelings.
                </p>
              </div>
            </div>
            <a href=" " className="er__joinBtn simplify_bottomBtn">
              Join the Waitlist
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Simplify;

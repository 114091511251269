import React from "react";

function SayGoodbye() {
  return (
    <>
      <section className="er__sayGoodbye">
        <div className="container">
          <div className="er__sayGoodbye_wrap">
            <h5>
              Say goodbye to missed opportunities, inconsistent responses, and
              constant monitoring. Welcome to Early Bird - your partner in
              efficient, effective, and always-on lead engagement.
            </h5>
            <a href="/join-waitlist" className="er__joinBtn">
              Join the Waitlist
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default SayGoodbye;

import React from "react";
import landingImg from "../assets/images/landing.png";
import Lottie from "react-lottie";
import * as animationData from "../assets/images/CoverAnimation.json";

function LandingSection() {
  const defaultOptions = {
    animationData: animationData,
  };

  return (
    <>
      <section className="er__landing">
        <div className="container">
          <div className="er__landing_wrap">
            <div className="er__landing_head">
              <h1>
                Secure your
                <br /> next client faster
              </h1>
              <div className="er__landing_imgWrap">
                <Lottie
                  options={defaultOptions}
                  isClickToPauseDisabled={true}
                  // height={400}
                  // width={400}
                  // isStopped={this.state.isStopped}
                  // isPaused={this.state.isPaused}
                />
                {/* <img src={landingImg} alt="landingImg"/> */}
              </div>
            </div>
            <div className="er__landing_bottom">
              <div className="er__landing_bottom_wrap">
                <div className="er__landing_bottom_left">
                  <p>
                  Harness the power of 24/7 AI proposals and messaging for your Upwork leads
                  </p>
                </div>
                <div className="er__landing_bottom_left_small">
                  <p>
                  Harness the power of 24/7 AI proposals and messaging for your Upwork leads
                  </p>
                </div>
                <div className="er__landing_bottom_right">
                  <p>
                    Unlock untapped revenue and constant streams of new work
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default LandingSection;

import React from "react";
import LandingSection from "../components/LandingSection";
import ModernFreelancer from "../components/ModernFreelancer";
import Achieve from "../components/Achieve";
import AboutEarly from "../components/AboutEarly";
import Simplify from "../components/Simplify";
import SayGoodbye from "../components/SayGoodbye";
import Features from "../components/Features";
import LeadResponse from "../components/LeadResponse";

function Home() {

  return (
    <>
      <LandingSection />
      <ModernFreelancer />
      <Achieve />
      <LeadResponse />
      <Features />
      <AboutEarly />
      <Simplify />
      <SayGoodbye />
    </>
  );
}

export default Home;

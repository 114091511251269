import React from "react";
import arrow_bird from "../assets/images/arrow_bird.png";
import bird_circle from "../assets/images/bird_circle.png";
import bird_shoot from "../assets/images/birds_shoot.png";

function Features() {
  const featureData = [
    {
      number: "01",
      title: "Fast AI proposal sending",
      src: arrow_bird,
      descriptions: [
        "<p>Forget about being late to the party: Early Bird bids on jobs within 5 minutes or less of being posted.</p>",
        "<p>AI-powered job filtering and boosting makes sure you<b> always have the best shot at catching a client’s eye.</b></p>",
        "<p>Say goodbye to wasted connects and say hello to AI-driven efficiency.</p>",
      ],
    },
    {
      number: "02",
      title: "Immediate Upwork chat message responses",
      src: bird_circle,
      descriptions: [
        "<p>Early Bird responds to Upwork messages for you 24/7 in your communication style–ensuring responses are both timely and personalized.</p>",
        "<p><b>Eliminate miscommunication and build rapport</b> with potential clients from the first interaction all the way through a call being booked for you to seal the deal.</p>",
      ],
    },
    {
      number: "03",
      title: "Sell more projects",
      src: bird_shoot,
      descriptions: [
        "<p>After Early Bird has done the heavy lifting and secured a video call appointment for you, all you have to do is show up and finalize the terms.</p>",
        "<p>Early bird takes care of the time-intensive sales development process for you so you have<b> more time to do the things you love.</b></p>",
      ],
    },
  ];
  return (
    <>
      <section className="er__features" id="features_early">
        <div className="container">
          <div className="er__features_wrap">
            <div className="er__features_title">
              <p>FEATURES</p>
              <h2>How does it work?</h2>
            </div>
            <div className="er__features_card">
              <div className="er__features_cardList">
                {featureData.map((feature, index) => (
                  <div className="er__features_cardItem" key={index}>
                    <div className="er__features_imgWrap">
                      <img src={feature.src} alt="image" />
                    </div>
                    <div className="er__features_cardInfo">
                      <h2 className="er__features_cardNo zenFont">
                        {feature.number}
                      </h2>
                      <div className="er__features_cardDet">
                        <p className="er__features_title">{feature.title}</p>
                        {feature.descriptions.map((desc, idx) => (
                          <div dangerouslySetInnerHTML={{ __html: desc }} />
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="er__features_btn">
              <a href="/join-waitlist" className="er__joinBtn">
                Join the Waitlist
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Features;

import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";
import logo_img from "../assets/images/logo 2.png";

function NavigationBar() {
  const [isScroll, setIsScroll] = useState(false);
  const [id, setId] = useState("");
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const navigateTo = (id) => {
    setExpanded(!expanded);
    const element = document.getElementById(id);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;

      window.scrollTo({
        top: elementPosition - 70,
        behavior: "smooth",
      });
    } else {
      navigate("/");
      setId(id);
      setIsScroll(true);
    }
  };

  const handleScroll = () => {
    // console.log("y position: ", window.scrollY);
    const navbar = document.querySelector(".er__navBar");
    //  set "er__fixed"
    if (window.scrollY > 25) {
      navbar.classList.add("er__fixed");
    } else if (window.scrollY === 0) {
      navbar.classList.remove("er__fixed");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScroll && id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [isScroll, id]);

  const onJoinWaitlist = () => {
    setExpanded(!expanded);
    navigate("/join-waitlist");
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className={"er__navBar"}
        expanded={expanded}
      >
        <Container>
          <Navbar.Brand href="/" className="er__logo zenFont">
            <div className="er__logo">
              <img src={logo_img} alt="logoImg" />
            </div>
            EARLY BIRD
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => setExpanded(!expanded)}
          />
          <Navbar.Collapse id="responsive-navbar-nav" className="er__rightMenu">
            <Nav className="er__menuList">
              <Nav.Link onClick={() => navigateTo("about_early")}>
                About.
              </Nav.Link>
              <Nav.Link onClick={() => navigateTo("features_early")}>
                Features.
              </Nav.Link>
              <Nav.Link onClick={() => navigateTo("review_early")}>
                Reviews.
              </Nav.Link>
              <Nav.Link href="mailto:maxwell@myearlybird.ai">Contact.</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link className="er__joinBtn" onClick={onJoinWaitlist}>
                Join the Waitlist
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavigationBar;

import React from "react";
import laptopImg from "../assets/images/proposal.jpg";

function LeadResponse() {
  const leadResponseData = [
    {
      title: "Consistent, Accurate Lead Responses and Proposals",
      descriptions: [
        "A reliable AI messaging system tailored to your unique style and tone.",
        "Trained on your past conversations, we ensure your responses authentically represent you.",
      ],
    },
    {
      title: "Focus on what matters",
      descriptions: [
        "Free up your time to focus on the work you love. Let us handle the initial lead generation and engagement, so you can concentrate on delivering high-quality results.",
      ],
    },
  ];
  return (
    <>
      <section className="er__leadRes">
        <div className="container">
          <div className="er__leadRes_wrap">
            <div className="er__leadRes_head">
              <h3>
                Finally, the proposal sending and lead response breakthrough is
                here
              </h3>
            </div>
            <div className="er__leadRes_det">
              <div className="er__leadRes_detWrap">
                <div className="er__leadRes_detLeft">
                  <p className="er__leadRes_ben top">BENEFITS</p>
                  {/* <div className="er__leadRes_cardList">
                    <div className="er__leadRes_detCard">
                      <p>Consistent, Accurate Lead Responses</p>
                      <p>
                        A reliable AI messaging system tailored to your unique
                        style and tone.
                      </p>
                      <p>
                        Trained on your past conversations, we ensure your
                        responses authentically represent you.
                      </p>
                    </div>
                    <div className="er__leadRes_detCard">
                      <p>Focus on what matters</p>
                      <p>
                        Free up your time to focus on the work you love. Let us
                        handle the initial lead engagement, so you can
                        concentrate on delivering high-quality results.
                      </p>
                    </div>
                  </div> */}
                  <div className="er__leadRes_cardList">
                    {leadResponseData.map((card, index) => (
                      <div className="er__leadRes_detCard" key={index}>
                        <p>{card.title}</p>
                        {card.descriptions.map((desc, idx) => (
                          <p key={idx}>{desc}</p>
                        ))}
                      </div>
                    ))}
                  </div>
                  <a href="/join-waitlist" className="er__joinBtn">
                    Join the Waitlist
                  </a>
                </div>
                <div className="er__leadRes_detRight">
                  <p className="er__leadRes_ben small">BENEFITS</p>
                  <div className="er__leadRes_rightInfo">
                    <p>24/7 Availability</p>
                    <p>
                      Never miss a lead again with our always-on response
                      system. Early Bird works around the clock, responding to
                      leads so you don't have to.
                    </p>
                  </div>
                  <div className="er__leadRes_rightImgWrap">
                    <img src={laptopImg} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default LeadResponse;

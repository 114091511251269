import React from "react";

function Footer() {
  return (
    <>
      <section className="er__footer">
        <div className="container">
          <div className="er__footer_wrap">
            <div className="er__footer_list">
              <div className="er__footer_copy">
                <p>&copy; 2024 Early Bird</p>
              </div>
              <div className="">
                <ul className="er__footer_links">
                  <li>
                    <a href="mailto:maxwell@myearlybird.ai">Contact</a>
                  </li>
                  <li>
                    <a href="/terms-and-conditions">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                </ul>
              </div>
              <div className="er__footer_social">
                <p>Socials:</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;

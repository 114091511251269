import React from "react";

function AboutEarly() {
  const projectData = [
    {
      project: "Landing a high value client on Upwork",
      description: [
        `"Early Bird transformed my Upwork experience. The AI responded to leads faster and more accurately than I could. I've landed more clients since using it!"`,
      ],
    },
    {
      project: "Maintaining a steady stream of projects",
      description: [
        `"I was struggling to keep up with incoming leads while working on current projects. Early Bird's 24/7 responses helped me maintain a steady stream of work without the constant distraction."`,
      ],
    },
    {
      project: "Scaling my freelance business",
      description: [
        `"As my business grew, I needed a solution to handle lead responses efficiently. Early Bird was the perfect solution, allowing me to scale without sacrificing quality."`,
      ],
    },
  ];
  return (
    <>
      <section className="er__aboutEarly" id="review_early">
        <div className="container">
          <div className="er__aboutEarly_wrap">
            <div className="er__aboutEarly_head">
              <h5>What people are <br/> saying about Early Bird</h5>
              <a href="/join-waitlist" className="er__joinBtn joinBtn_top">
                Join the Waitlist
              </a>
            </div>
            <div className="er__aboutEarly_bottom" id="reviews_early">
              <p className="er__aboutEarly_feed">CLIENT FEEDBACK</p>
              <div className="er__aboutEarly_cardWrap">
                {projectData.map((project, index) => (
                  <div className="er__aboutEarly_card" key={index}>
                    <div className="er__aboutEarly_title">
                      <p>
                        PROJECT:
                        <br /> {project.project}
                      </p>
                    </div>
                    <div className="er__aboutEarly_info">
                      {project.description.map((desc, idx) => (
                        <p key={idx}>{desc}</p>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <a href="" className="er__joinBtn joinBtn_bottom">
                Join the Waitlist
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutEarly;
